import { FieldType } from '@wix/wix-data-schema-types'

const ASCENDING = 'asc'
const DESCENDING = 'desc'

const getSiblingPage = async ({
  dataProvider,
  collectionName,
  directionTowardSibling,
  dynamicPagesData: {
    dynamicUrl,
    userDefinedFilter,
    dynamicUrlPatternFieldsValues,
    sort,
    sortFields,
    patternFields,
  } = {},
}) => {
  if (dynamicUrl == null || !patternFields.length) {
    return null
  }
  const item = await dataProvider.getSibling({
    collectionName,
    sort,
    sortFields,
    directionTowardSibling,
    fieldValues: dynamicUrlPatternFieldsValues,
    filter: userDefinedFilter,
  })

  const fieldName = getPageLinkFieldName({
    dynamicUrl,
    schema: dataProvider.getSchema(collectionName),
  })

  if (!item || !fieldName) {
    return undefined
  }

  return item[fieldName]
}

const getPageLinkFieldName = ({ schema, dynamicUrl }) => {
  const [fieldName] =
    Object.entries(schema.fields).find(
      ([_, field]) =>
        field.type === FieldType.pageLink &&
        field.calculator.config.pattern === dynamicUrl,
    ) || []

  return fieldName
}

export default ({ dataProvider, dynamicPagesData, collectionName }) => ({
  getNextDynamicPageUrl: () =>
    getSiblingPage({
      dataProvider,
      dynamicPagesData,
      collectionName,
      directionTowardSibling: ASCENDING,
    }),
  getPreviousDynamicPageUrl: () =>
    getSiblingPage({
      dataProvider,
      dynamicPagesData,
      collectionName,
      directionTowardSibling: DESCENDING,
    }),
})
